interface Props {
  round: string;
  turn: string;
  maxTurn: string;
  maxRound?: string;
}

export const RoundCounter = ({
  round,
  turn,
  maxTurn,
  maxRound = "3",
}: Props) => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <span className={`text-base md:text-lg`}>
        Round{" "}
        <span className="font-normal">
          {round}/{maxRound}
        </span>
      </span>
      <span className={`text-base md:text-lg`}>
        Turn{" "}
        <span className="font-normal">
          {turn}/{maxTurn}
        </span>
      </span>
    </div>
  );
};
