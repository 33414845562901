import { useEffect, useState } from "react";

import { useGame } from "../../../../hooks/useGame";

interface IBattleCountdownProps {
  start: number;
  onEndTurn?: () => void;
  minimal?: boolean;
}

export const BattleCountdown = ({
  start,
  onEndTurn,
  minimal,
}: IBattleCountdownProps) => {
  const { stage } = useGame();
  const endGame = stage.includes("reward") || stage === "reward-draw";
  const [animationOn, setAnimationOn] = useState(false);

  useEffect(() => {
    if (start > 0) {
      setAnimationOn(true);
      const timer = () =>
        setTimeout(() => {
          start--;
        }, 1000);
      timer();
    }

    if (start <= 0) {
      setAnimationOn(false);
      onEndTurn && onEndTurn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    if (endGame) {
      setAnimationOn(false);
    }
  }, [endGame]);

  if (minimal) {
    return (
      <div className="flex items-center justify-center rounded-lg bg-[#121212c8]">
        <span className="text_shadow  text-base drop-shadow-md">
          {start <= 0 || endGame ? "0s" : `${start}s`}
        </span>
      </div>
    );
  }

  return (
    <div className="circle-progress max-w-[145px]">
      <div className="outer">
        <div className="inner">
          <span className="text_shadow text-lg drop-shadow-md">
            {start <= 0 || endGame ? "0s" : `${start}s`}
          </span>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100px"
        height={"100px"}
      >
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stopColor="#DA22FF" />
            <stop offset="100%" stopColor="#9733EE" />
          </linearGradient>
        </defs>
        <circle
          className={animationOn ? "animation-on" : ""}
          cx="50"
          cy="50"
          r="40"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
